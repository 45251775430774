import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

// const structData = `
// {
//   "@context": "https://schema.org/",
//   "@type": "WebSite",
//   "name": "The Octet Institute",
//   "url": "https://www.theoctetinstitute.com/",
// }`

// const ytLinks = [
//   {
//     title: "Oppurtunities in VLSI",
//     src: "https://www.youtube.com/embed/TUmqzJl-vSI",
//   },
//   {
//     title: "Introduciton to Verilog Course",
//     src: "https://www.youtube.com/embed/DoNlEGao3RM",
//   },
//   {
//     title: "Introduciton to Verilog (Part-1)",
//     src: "https://www.youtube.com/embed/fWBz5XjnwWk",
//   },
// ]

const ytLinks2 = [
  {
    title: "Oppurtunities in VLSI",
    vid: "TUmqzJl-vSI",
  },
  {
    title: "Introduciton to Verilog Course",
    vid: "DoNlEGao3RM",
  },
  {
    title: "Introduciton to Verilog (Part-1)",
    vid: "fWBz5XjnwWk",
  },
]
// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___p_date, order: DESC }, limit: 6) {
        edges {
          node {
            frontmatter {
              p_date(formatString: "DD MMM YYYY")
              title
              description
            }
            slug
            excerpt(pruneLength: 300)
          }
        }
      }
    }
  `)

  return (
    <Layout isHome="false">
      <SEO />
      <section className="container">
        <div className="banner">
          <StaticImage
            src="../images/banner-img.jpg"
            alt="The Octet Institute Logo"
            placeholder="blurred"
            // layout="fixed"
            loading="eager"
            height="100%"
            className="image-wrapper"
          />
          <h1>
            Welcome to <span>The Octet Institute</span>
          </h1>
          <p>
            where we make learning <span>VLSI</span> easier
          </p>
          <Link to="/content/">
            Explore Contents<span>&gt;</span>
          </Link>
          <div className="trans" height="50px"></div>
        </div>

        <h2>Recent Uploads</h2>

        <div className="uploads">
          <aside>
            <div className="android-ad">
              <p>
                Now you can download our app on Google Play to access the
                contents easily on the go.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.theoctinst.theoct&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </aside>

          {data.allMdx.edges.map(item => (
            <div
              role="button"
              className="card"
              key={item.index}
              onClick={() => navigate(`/content/${item.node.slug}/`)}
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === "Enter") navigate(`/content/${item.node.slug}/`)
              }}
            >
              <h3>{item.node.frontmatter.title}</h3>
              <h5>{item.node.frontmatter.p_date}</h5>
              <p>{item.node.excerpt}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="36px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              </svg>
            </div>
          ))}
        </div>

        <h2>Videos</h2>
        <div className="corosel">
          <div className="holder">
            {/* {ytLinks.map(item => (
              <iframe
                key={item.title}
                width="560"
                height="315"
                src={item.src}
                title={item.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            ))} */}

            {ytLinks2.map(item => (
              <iframe
                key={item.title}
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${item.vid}`}
                srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${item.vid}?autoplay=1><img src=https://img.youtube.com/vi_webp/${item.vid}/hqdefault.webp alt='Video ${item.title}'><span>▶</span></a>`}
                title={item.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            ))}
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/TUmqzJl-vSI"
              title="Oppurtunities in VLSI"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe> */}
          </div>
        </div>
      </section>
      <section className="ad-sec">
        <div className="ad"></div>
        <div className="ad"></div>
        <div className="ad"></div>
        <div className="ad"></div>
      </section>
      {/* <div className="action-btn">
				<img src="assets/arrow.png" width="100%" height="100%" />
			</div> */}{" "}
    </Layout>
  )
}

export default IndexPage
